<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';
	import {VueMasonryPlugin} from 'vue-masonry';
	Vue.use(VueMasonryPlugin)

	export default {
		extends: BaseVue,
		data() {
			return {
				datas:{},
				datas2:{},
				points:{}
			}
		},
		computed:{
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: this.web.lbl_our_cities,
					link: { name: 'Cities' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});

			this.getApi()
		},
		methods: {
			getApi() {
				Gen.apirest("/api-cities",{},(err,resp)=>{
					this.$root.topProgress.done()
					if(err) console.log(err)
					this.datas = resp.data
					this.datas2 = resp.data2
					this.points = resp.point
					setTimeout(() => { SEMICOLON.widget.extras(); }, 100);
				})
			},
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
		<section class="section nobottommargin notopmargin wall-bg">
			<div class="container">
				<BreadCrumb :data="breadCrumb"></BreadCrumb>
				<div class="heading-title">
					<h2>{{web.lbl_our_cities}}</h2>
				</div>
				<div class="office-map">
					<img :src="assets('fo_images','indo_map.png')" alt="Peta Indonesia" class="map">
					<a v-for="(v,k) in points" :key="k" class="map-pointer" data-container="body" data-toggle="popover" data-trigger="hover" data-placement="bottom" :data-content="v.address" aria-describedby="popover320813" :style="'top: '+v.point_y+'%;  left: '+v.point_x+'%;'"></a>
				</div>
				<div class="ow-address">
					<div class="row masonry-container" v-masonry transition-duration="3s" item-selector=".masonry_item">
						<div v-masonry-tile class="col-md-6 masonry_item" v-for="(v,k) in datas" :key="k">
							<div class="ow-region">
								<h3>{{v.cities}}</h3>
								<ul>
									<li v-for="(v2,k2) in v.data" :key="k2" v-html="v2.address">
										<!-- <p v-html="v.address"></p> -->
									</li>
								</ul>
							</div>
						</div>
						<!-- <div class="col-md-6">
							<div class="ow-region" v-for="(v,k) in datas" :key="k">
								<h3>{{v.cities}}</h3>
								<ul>
									<li v-for="(v2,k2) in v.data" :key="k2" v-html="v2.address">
									</li>
								</ul>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</section>
	</div>
</template>